.next-instructor-form{
  #form{
    .block{
      padding: 30px;
    }

    h4{
      color: black;
    }
  }
}