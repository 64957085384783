@import "../sizer.scss";
@import "../color.scss";

.product {
  .item-playlist {
    position: relative;
    display: flex;
    width: 100%;
  }

  .lock-item-playlist {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(70, 70, 70, 0.8);
  }

  .video-not-found {
    padding-top: 56.25%;
    position: relative;

    .display-not-found {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  a.media:hover {
    color: #ffffff;
  }

  .accordion {
    @media (max-width: $ipad-size) {
      h5 {
        font-size: 14px;
      }
    }
  }

  .playlist-button {
    padding-top: 1rem;
    display: flex;
    justify-content: center;

    button {
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #343434;
      color: white;
      cursor: pointer;
    }

    button:hover {
      -webkit-filter: brightness(115%);
      filter: brightness(115%);
    }
  }

  .progress {
    height: 4px;
  }

  .progress-inner {
    background-color: $color-tiger;
  }

  .progress-outer {
    border-radius: 0px;
  }

  .md-3cm {
    @media (max-width: $mobile-size) {
      width: 3cm;
    }
  }

  .check-item {
    text-align: right;

    @media (max-width: $mobile-size) {
      text-align: left;
    }
  }

  .md-pd-tb-10 {
    @media (max-width: $mobile-size) {
      padding: 10px 0px 10px 0px;
    }
  }

  .instructor-title {
    color: $color-tiger;
  }

  .instructor-name {
    color: black;
  }

  .instructor-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .media-middle {
    align-self: center !important;
  }

  .media-left {
    padding-right: 20px;
  }

  .syllabus-item {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .media {
      @media (max-width: $mobile-size) {
        display: block;
      }
    }
  }

  .syllabus-text {
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    opacity: 0.5;
    color: $color-black;
  }

  .syllabus-title {
    color: black;
    margin: 0px;
  }

  .background-products {
    background-image: url("/product/background-products.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .background-products-overlap {
    background: rgba(0, 0, 0, 0.5);
    color: white !important;
    text-align: center;
  }

  .text-white {
    color: white !important;
  }

  .content-center {
    justify-content: center;
    display: flex;
  }

  .syllabus-heading {
    color: black;
    margin-bottom: 8px;
  }

  .player-nav {
    display: block;
    padding: 20px;
    height: 78px;
    background-color: white;

    @media (min-width: $mobile-size) {
      display: none;
    }

    a {
      margin: 0px;
      font-size: 75%;
    }

    a.disabled {
      opacity: 0.3;
    }

    p {
      text-align: center;
    }

    .media {
      align-items: center;
    }
  }

  .player-progress {
    width: 40%;
    height: 4px;
  }

  .track-count.active {
    color: $color-tiger;
    font-weight: 600;
  }

  .track-count {
    font-size: 0.8125rem;
    width: 10px;
    white-space: nowrap;
  }

  .track {
    background-color: #343434;
    padding: 10px 12px;
    margin: 0;
    align-items: center;
  }

  .track-title {
    font-size: 0.8125rem;
    margin: 0px;
  }

  .media-middle {
    vertical-align: middle;
  }

  .playlist {
    font-size: 16px;
    height: 100%;
    background: #343434;
  }

  .playlist-title h3 {
    font-size: 0.75rem;
    margin: 0 0 0 0;
    opacity: 0.6;
    white-space: nowrap;
    color: rgb(255, 255, 255);
  }

  .playlist-title h2 {
    font-size: 0.875rem;
    margin: 0 0 0 0;
    color: white;
  }

  .playlist-title {
    padding: 20px 30px;
    background-color: #252525;
  }

  .player {
    margin-bottom: 30px;
    position: relative;
    background: #252525;
  }

  .player-video,
  .quiz-session,
  .download-certificate-session {
    width: 70%;
    display: inline-block;
    vertical-align: top;

    @media (max-width: $mobile-size) {
      width: 100%;
    }
  }

  .player-playlist {
    width: 30%;
    display: inline-block;
    top: 0;
    bottom: 0;
    position: absolute;
    overflow: auto;

    @media (max-width: $mobile-size) {
      display: none;
    }
  }

  .link-file {
    background-color: white;
    padding: 20px;

    a {
      color: #000;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  .nev-sticky {
    position: sticky;
    width: 100%;
    top: 84px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    overflow: hidden;
  }

  .nev-product {
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .left-nev-product {
    @media (max-width: $mobile-size) {
      width: 70%;

      p {
        font-size: 12px;
      }
    }
  }

  .right-nev-product {
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }

  .product-announcement {
    display: flex;
    flex-direction: row;
    background-color: $color-tiger;
    color: white;
    border-radius: 5px;
    padding: 10px;

    span {
      white-space: pre-line;

      @media (max-width: $mobile-size) {
        font-size: 12px;
      }
    }
  }

  .accordion-non-focus {
    background-color: rgb(238, 238, 238) !important;
  }

  .accordion-frame {
    border-radius: 0px !important;
  }

  .accordion-outside {
    padding: 0px !important;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: rgb(191, 191, 191);
  }

  .accordion {
    padding: 25px 30px !important;
  }

  .accordion-active {
    color: white;
    background-color: rgb(14, 27, 32) !important;

    h5 {
      color: white;
    }
  }

  .block-type-accordion {
    padding: 0px 0px !important;
  }
}

.popup-product-sale-overlay {
  padding: 0px !important;
}

.popup-product-sale-content {
  margin-top: 5rem !important;
  padding: 5rem 1rem;
  width: 100%;
  background-color: $color-buttermilk;

  h3 {
    color: $color-tiger;
    font-weight: 600;
  }

  h6 {
    color: $color-tiger;
  }

  .pop-modal {
    .close {
      width: 25px;
      height: 25px;
      align-items: center;
      top: 3rem;
      right: 1rem;

      p {
        margin: 0px;
        opacity: 0.7;
      }
    }
  }
}

.popup-certificate-content {
  text-align: center;
  padding: 25px;
  display: flex;
  justify-content: center;
  width: 60%;
  background-color: $color-buttermilk;
  border-radius: 8px;
  border: none;

  @media (max-width: $mobile-size) {
    width: 90%;
    padding: 16px;
  }

  @media (max-width: $ipad-size) and (min-width: $mobile-size) {
    width: 85%;
  }

  .pop-modal {
    width: 80%;
    @media (max-width: $mobile-size) {
      width: 90%;
    }
  }

  .popup-container {
    .renewal-detail {
      width: 100%;
      padding-left: 0;
      padding-top: 20px;
      h5 {
        font-weight: 700;
      }
    }

    .recommended-detail {
      margin-bottom: 88px;
      @media (max-width: $mobile-size) {
        margin-bottom: 0;
      }

      @media (min-width: $ipad-size) {
        margin-bottom: 80px;
      }

      .recommended-course-container {
        @media (max-width: $mobile-size) {
          flex-direction: column;
        }
        .recommended-course-detail {
          @media (max-width: $mobile-size) {
            width: 100%;
          }
        }
      }
    }

    .blank-box {
      margin-bottom: 82px;
      @media (max-width: $mobile-size) {
        margin-bottom: 0;
      }
    }

    .btn-more-detail {
      border-color: #d3d3d3;
      background-color: white;
      color: black;
      width: fit-content;
    }

    .btn-more-detail:hover {
      color: black;
    }
  }
}

.download-certificate-session {
  background-color: #f7f7f7;
}

.download-certificate-container {
  text-align: center;
  padding: 25px;
  margin: auto;
  justify-content: center;
  width: 75%;
  background-color: $color-buttermilk;
  border: solid;
  border-color: #d3d3d3;
  border-radius: 4px;

  @media (max-width: $mobile-size) {
    width: 100%;
  }

  .btn-more-detail {
    border-color: #d3d3d3;
    background-color: white;
    color: black;
    width: fit-content;
  }

  .btn-more-detail:hover {
    color: black;
  }

  .renewal-detail {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
    h5 {
      font-weight: 700;
    }
  }

  .recommended-detail {
    margin-bottom: 80px;
    .recommended-course-container {
      @media (max-width: $ipad-size) {
        flex-direction: column;
      }

      .recommended-course-detail {
        @media (max-width: $ipad-size) {
          width: 100%;
        }
      }
    }
  }
}

.get-certificate-detail {
  width: 100%;
  background-color: $color-blue;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 5px #888888;
  h5 {
    font-weight: 700;
  }
  .btn-small {
    @media (max-width: $mobile-size) {
      display: inline-block;
      width: 100%;
      font-size: 100%;
    }
  }
}

.recommended-detail {
  width: 100%;
  padding-left: 0;
  padding-top: 20px;
  color: $color-tiger;
  h5 {
    color: $color-tiger;
    font-weight: 700;
  }

  .recommended-course-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .recommended-course-detail {
      width: 49%;
      text-align: center;
      align-self: center;
      .course-name {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.next-instructor-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  color: $color-black;
  font-weight: 600;
  padding: 8px 16px;
}
