.guard-contact {

  .card-form {
    border: 1px solid gainsboro;
  }

  .form-guard {
    border: 1px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    padding: 25px 40px 10px 40px;
  }
}