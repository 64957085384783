@import "../sizer.scss";
@import "../color.scss";

.review {
  #block-titel .block {
    padding: 30px;
  }

  #block-titel h1 {
    font-size: 80px;

    @media (max-width: $mobile-size) {
      font-size: 28px;
    }
  }

  #block-titel h4 {
    font-size: 20px;

    @media (max-width: $mobile-size) {
      font-size: 18px;
    }
  }

  #block-person-1 .block-type-image {
    margin-top: 30px;
  }

  #block-person-1 .block {
    border: 4px solid $color-tiger;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 30px;
    margin: auto;
  }

  #block-person-1 .row {
    @media (max-width: $mobile-size) {
      display: block
    }
  }

  #block-person-1 .container {
    padding-left: 75px;
    padding-right: 75px;
  }

  #block-person-1 .frame {
    margin-top: -50px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;

    @media (max-width: 990px) {
      text-align: center !important;

      .block {
        justify-content: center;
      }
    }
  }

  #block-person-1 .image {
    @media (max-width: $mobile-size) {
      margin: 0px;
    }
  }

  #block-person-1 .container {
    @media (max-width: $mobile-size) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #block-person-1 p {
    font-size: 14px;
    margin: 0px;
  }

  #block-person-1 a:hover {
    -webkit-filter: brightness(115%);
    filter: brightness(115%);
  }

  #block-person-1 .block-text {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 8%;
    padding-right: 0px;
    margin-left: -30px;

    @media (max-width: $mobile-size) {
      width: 100%;
      padding: 0px;
      margin-top: 20px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .section-feature-1 {
    .block {
      border: 3px dotted $color-pink;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 30px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    .grid-container {
      @media (min-width: $ipad-size) {
        display: grid !important;
        grid-template-columns: 25.6% 25.6% 25.6% 25.6% !important;
      }

      @media (min-width: $mobile-size) and (max-width: $ipad-size) {
        display: grid !important;
        grid-template-columns: 50% 50% !important;
      }
    }

    .feature-image {
      border-radius: 4px;
    }
  }
}