.forgot-password {

  h3,
  h4 {
    color: #2c3e50;
    margin: 0px;
  }

  .text-card{
    background-color: white;
    padding: 40px;
  }
}