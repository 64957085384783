@import "../sizer.scss";
@import "../color.scss";

.renewal {
  .renewal-section {
    margin-bottom: 20px;
  }

  .text-line {
    display: inline-block;
  }

  .container-desktop {
    display: block;

    @media (max-width: $mobile-size) {
      display: none;
    }
  }

  .container-mobile {
    display: none;

    @media (max-width: $mobile-size) {
      display: block;
    }
  }

  .block {
    @media (max-width: $mobile-size) {
      display: flex;
      justify-content: center;
    }
  }
}
