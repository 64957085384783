@import "../sizer.scss";
@import "../color.scss";

.index {
  .inline-courses {
    @media (min-width: $mobile-size) {
      display: inline-flex;
      flex-direction: row;
    }

    .courses-latest {
      padding: 15px;
      width: 100%;
      height: 100%;
    }
  }

  .disclaimer-text {
    font-size: 16px;
    margin-top: 1.25rem;
    margin-bottom: 0;
    color: $color-pink;
  }

  .feature-text {
    font-size: 16px;
    line-height: 1.1;
  }

  .block {
    @media (max-width: $mobile-size) {
      display: flex;
      justify-content: center;
    }
  }

  .coming-soon {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}